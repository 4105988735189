import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGuards(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/guard/get`, {
            apikey: this.state.customConfig.token,
            perPage: payload.perPage,
            currentPage: payload.currentPage,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addGuard(ctx, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`${this.state.customConfig.baseURL}/web/guard/create`, {
    //         apikey: this.state.customConfig.token,
    //         perPage: payload.perPage,
    //         currentPage: payload.currentPage,
    //       })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchArea() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/area/dropdown`, {
            apikey: this.state.customConfig.token,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
